#footer_inner {

    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    height: 100%;
    justify-content: flex-start;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:stretch;
    -webkit-align-items:stretch;
    width: 100%;
    min-height:0;
    position: relative;
    
	// &:before {
	// 	content: '';
	// 	height: 1px;
	// 	background-color: rgba($black,0.20);
	// 	position: absolute;
	// 	left: 0;
	// 	top: 0;
	// 	right:0;
	// }

	#footer_flip_axis {
		@include box-sizing(border-box);
		padding: 10px;
	}

	#footer_hide_chart {
		@include box-sizing(border-box);
		padding: 10px;
		padding-left: 0;
		position: relative;
	}

	#footer_tabs {
		width: 100%;
        flex-grow:1;
        flex: 1 1 auto;
        -webkit-flex: 1 1 auto;
        position: relative;
        @include box-sizing(border-box);
        overflow: auto;
		@include box-sizing(border-box);
		padding: 0 0 5px;
	}

	#footer_controls {
		@include box-sizing(border-box);
		padding: 10px;

		&.hide_controls {
			display: none;
		}

		#pan_controls {
			width: 60px;
			
			button {
				float: left;
			}
		}
	}

	#footer_export {
		@include box-sizing(border-box);
		padding: 10px;
	}

	#footer_actions {
		@include box-sizing(border-box);
		padding: 10px;

		>ul {
			margin:0;
			padding:0;
			list-style: none;
			font-size: 0;
			white-space: nowrap;

			>li {
				font-size: $font-size;
				display: inline-block;
				margin-left: 10px;

				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
}



#result_tab_container {

	>ul {
		margin:0px 0 0;
		padding:0;
		list-style: none;
		display: inline-block;
		font-size: 0;
		white-space: nowrap;
		
		>li {
			display: inline-block;
			font-size: 0;
			margin-right: 10px;
			position: relative;

			&:last-child {
				margin-right: 0;
			}

			>ul {
				margin:0;
				padding:0;
				list-style: none;
				width: 100%;
				white-space: nowrap;
				display: inline-block;
				font-size: 0;

				>li {
					display: inline-block;
					font-size: 0;
					margin-right: 2px;
					position: relative;
					overflow: hidden;

					
				}
			}
		}
	}
}


button {
	&.btn_sheet {
		background-color: rgba($starling_black,0.1);
		@include border-radius(0);
		@include border-bottom-radius(3px);
		padding: 10px 10px 7px 10px;
		position: relative;
		border-width: 0 0 3px 0;
		border-style: solid;
		border-color:rgba($starling_black,0.1);
		color: $app_color;
		text-transform: inherit;
		font-size: 0.86rem;
		font-weight: 400;


		&:hover, &:focus {
			color: $starling_black;
			background-color: $white;
			border-color:$white;
		}

		&.btn_sheet_active {
			background-color: $white;
			color: $starling_black;
			border-color:$starling_black;
			@include box-shadow(0 2px 10px rgba($black,0.1));

		}
	}
}
