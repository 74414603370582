/*
===============
 GLOBAL STYLES
===============
*/

html {
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  font-weight: 400;
  text-rendering: optimizelegibility;
}

body,
html {
  --navigation-width: 270px;

  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  overflow: hidden;
  font-family: 'Source Sans Pro', Helvetica, sans-serif !important;

  // Firefox scroll-bars
  scrollbar-color: #2b495ea8 #edf1f6;
  scrollbar-width: thin;
}

:host ::ng-deep {
  .cursor-pointer {
    cursor: pointer;
  }
}

// Webkit scroll-bars
::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-thumb {
  background: #2b495ea8;
  background-clip: padding-box;
  border-radius: 8px;
  border: 4px solid rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 1px #edf1f6;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  background: #edf1f6;
}

.k-splitbar {
  width: 12px;

  .k-collapse-next {
    font-size: 20px;
  }
}

.k-splitbar:active {
  background: #2b495e59;
}


.gc-list-control {
  overflow: auto;
  overflow: overlay;
  min-width: 100px;
  max-width: 400px;
  max-height: 350px;

  .gc-list-control-selected-item {
    background: #5581a4 !important;
  }
}

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i');
@import '../src/app/styles/base.scss';
@import 'app/styles/base/icons/style.scss';
