// This structure is mainly used for Checkboxes and Radio Lists
fieldset.form_row {
  margin-bottom: 10px;
  position: relative;

  &.no_padding {
    margin-bottom: 0;
  }

  legend {
    &.hidden_legend {
      font-size: 0;
      position: absolute;
    }
  }

  &.inline {
    position: relative;

    legend {
      width: 40%;
      position: absolute;
      left: 0;
      top: 0;
    }

    > .form_group {
      width: 60%;
      margin-left: 40%;
    }

    &.auto_width {
      display: inline-block;

      legend {
        width: auto;
        margin-right: 10px;
        position: relative;
        left: auto;
        top: auto;
      }

      > .form_group {
        display: inline-block;
        width: auto;
        margin-left: auto;
        white-space: nowrap;
      }
    }

    &.split_50 {
      > .data_label {
        float: left;
        width: 50%;
        // padding: 5px 5px 5px 0;
      }

      > .data_value {
        float: left;
        width: 50%;
        // padding: 5px 5px 5px 0;
      }
    }
  }

  &.layout_horizontal {
    > .form_group {
      > .form_row {
        width: auto;
        display: inline-block;
        float: left;
        margin-right: 10px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  &.layout_stacked {
    > .form_group {
      &.split_2 {
        > .form_row {
          width: 50%;
          float: left;

          label {
            display: block;
          }
        }
      }

      > .form_row {
        width: auto;
        display: block;
      }
    }
  }

  .form_row {
    margin-bottom: 0;
  }
}

.form_row {
  margin-bottom: 10px;
  position: relative;

  &:after {
    clear: both;
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
  }

  &.no_padding {
    margin-bottom: 0;
  }

  &.align_center {
    text-align: center;
  }

  &.align_to_label {
    padding-left: 40%;
  }

  &.align_to_label_50 {
    padding-left: 50%;
  }

  &.inline {
    > .form_label {
      float: left;
      width: 40%;

      label {
        white-space: nowrap;
      }
    }

    > .form_input {
      float: left;
      width: 60%;
    }

    > .form_adder {
      margin-left: 40%;
      margin-bottom: 5px;
      float: left;
      clear: both;
      width: 60%;
    }

    &.split_50 {
      > .form_label {
        float: left;
        width: 50%;
      }

      > .form_input {
        float: left;
        width: 50%;
      }
    }

    .form_row_repeater {
      margin-bottom: 5px;
      float: left;
      width: 100%;
      clear: both;

      > .form_label {
        float: left;
        width: 40%;
        display: block;

        &.hidden_label {
          position: absolute;
          font-size: 0;
          @include opacity(0);

          label {
            padding: 0;
          }
        }

        label {
          line-height: 20px;
          padding: 5px 5px 5px 0;
          display: block;
          color: $mid_grey;

          .label_right {
            float: right;
          }
        }
      }

      > .form_input {
        float: left;
        width: 60%;
      }
    }
  }

  &.flex {
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    align-items: flex-start;
    -webkit-align-items: flex-start;
    // width: 100%;
    min-height: 0;

    > .form_label {
      // margin-right: 10px;
    }

    > .form_input {
    }

    &.label_full {
      > .form_label {
        flex-grow: 1;
      }
    }

    &.input_full {
      > .form_input {
        flex-grow: 1;
      }
    }
  }

  > .form_label {
    display: block;

    &.hidden_label {
      position: absolute;
      font-size: 0;
      @include opacity(0);

      label {
        padding: 0;
      }
    }

    label {
      line-height: 20px;
      padding: 5px 5px 5px 0;
      display: block;
      color: $mid_grey;

      .label_right {
        float: right;
      }
    }
  }

  > .form_input {
    display: block;

    &.hidden {
      position: absolute;
      opacity: 0;
      font-size: 0;
      left: 0;
      top: 0;
    }
  }

  .form_input {
    // Readonly Lock Icon
    &.readonly {
      position: relative;

      .inputbox {
        padding-right: 25px !important;
      }
    }

    // Has Inline Button
    &.input_has_button {
      position: relative;

      .inputbox {
        padding-right: 35px !important;
      }

      .form_input_button {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    // Has Helper Text
    &.input_has_helper {
      position: relative;
      padding-right: 30px;
      @include box-sizing(border-box);

      .form_input_helper {
        position: absolute;
        line-height: 20px;
        top: 5px;
        right: 0;
        min-width: 25px;
        text-align: left;
      }
    }

    .inputbox_inline_text {
      margin-left: 5px;
      display: inline-block;
    }
  }

  .form_error {
    display: block;
    color: $red;
    line-height: 20px;
    padding: 5px 0;
  }

  &.auto_width {
    display: inline-block;
  }
}

.flex_group {
  display: flex;
  flex-direction: rows;
  -webkit-flex-direction: rows;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  margin-bottom: 10px;

  &.flex_end {
    justify-content: flex-end;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &.flex_no_wrap {
    flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
  }

  .flex_row {
    &:after {
      clear: both;
      content: '';
      display: block;
      height: 0;
      visibility: hidden;
    }

    > .form_label {
      width: auto;

      &.hidden_label {
        position: absolute;
        font-size: 0;
        @include opacity(0);

        label {
          padding: 0;
        }
      }
    }

    > .form_input {
      width: auto;
    }

    &.flex_grow {
      flex-grow: 1; // modify
      flex: 1 1 auto;
      -webkit-flex: 1 1 auto;
    }
  }

  .flex_row_action {
    margin-left: auto;
    padding-left: 10px;
  }
}

.form_helper_txt {
  line-height: 20px;
  padding: 5px 0;
  display: block;
}

.form_supplementary_text {
  line-height: 20px;
  padding: 5px 0;
  display: block;
  font-style: italic;
  color: $mid_grey;
}

/* Data Rows */

.data_group {
  display: flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  width: 100%;
  min-height: 0;

  &.stacked {
    flex-direction: column;
    -webkit-flex-direction: column;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
  }

  .data_row {
    flex: 1 1 auto;
  }
}

.data_row {
  display: block;
  margin-bottom: 10px;
  position: relative;

  &.width_10 {
    flex: 0 0 10%;
  }

  &.width_15 {
    flex: 0 0 15%;
  }

  &.width_20 {
    flex: 0 0 20%;
  }

  &.width_25 {
    flex: 0 0 25%;
  }

  &.width_30 {
    flex: 0 0 30%;
  }

  &.width_35 {
    flex: 0 0 35%;
  }

  &.width_40 {
    flex: 0 0 40%;
  }

  &:after {
    clear: both;
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
  }

  &.no_padding {
    margin-bottom: 0;
  }

  &.inline {
    > .data_label {
      float: left;
      width: 40%;
      // padding: 5px 5px 5px 0;
    }

    > .data_value {
      float: left;
      width: 60%;
      // padding: 5px 5px 5px 0;
    }

    &.split_50 {
      > .data_label {
        float: left;
        width: 50%;
        // padding: 5px 5px 5px 0;
      }

      > .data_value {
        float: left;
        width: 50%;
        // padding: 5px 5px 5px 0;
      }
    }
  }

  &.compact {
    > .data_label {
      padding: 0;
    }
  }

  > .data_label {
    display: block;
    line-height: 20px;
    display: block;
    @include box-sizing(border-box);
    color: $mid_grey;
    padding: 5px 0px 5px 0;

    &.hidden_label {
      position: absolute;
      font-size: 0;
      @include opacity(0);
    }

    &.no_padding {
    }
  }

  > .data_value {
    display: block;
    line-height: 20px;
    display: block;
    @include box-sizing(border-box);

    &.large_text {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 30px;
    }
    &.medium_text {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 30px;
    }
  }

  &.mimic_form_row {
    > .data_label {
      padding: 5px 0;
    }

    > .data_value {
      padding: 5px 0;
    }
  }
}

fieldset {
  position: relative;

  &.grouped {
    border: 1px solid $border-color;
    padding: 0 15px 10px;
    margin-bottom: 10px;

    &.no_margin {
      margin-bottom: 0;
    }

    &.no_border {
      border: 0px solid;
    }

    &.no_padding {
      padding: 10px 0;
    }

    &.rounded {
      @include border-radius(4px);
    }
  }

  legend.legend_title {
    font-size: 1rem;
    line-height: 30px;
    display: block;
    background-color: $white;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: -10px;
    padding-bottom: 0;
    padding-top: 0;
  }

  &.split_50_50 {
    &:after {
      clear: both;
      content: '';
      display: block;
      height: 0;
      visibility: hidden;
    }

    > .form_row {
      float: left;
      vertical-align: top;
      width: 50%;
      @include box-sizing(border-box);

      &:nth-child(odd) {
        padding-left: 20px;
      }

      &:nth-child(even) {
        padding-right: 20px;
      }
    }
  }

  .fieldset_remove {
    position: absolute;
    right: 5px;
    top: 0px;
    background-color: $white;
  }

  .well_container {
    margin-top: 10px;
    padding-bottom: 5px;
    @include border-radius(4px);
    background-color: $lighter_grey;
    padding-top: 15px;
    border: 0px solid;

    &:first-of-type {
      margin-top: 0;
    }

    legend {
      padding-left: 10px;
      padding-right: 10px;
      // background-color: $white;
      margin-left: -10px;
      float: left;
      width: 100%;
      padding-top: 0;
      padding-bottom: 10px;
    }

    &:after {
      clear: both;
      content: '';
      display: block;
      height: 0;
      visibility: hidden;
    }

    > .form_row {
      float: left;
      vertical-align: top;
      width: 50%;
      @include box-sizing(border-box);

      &:nth-child(odd) {
        padding-left: 20px;
      }

      &:nth-child(even) {
        padding-right: 20px;
      }
    }

    .fieldset_remove {
      background-color: transparent;
      top: 10px;
      right: 5px;
    }
  }
}
