@import './variables';

// Inputs

.k-window,
.k-dialog {
  border-radius: 4px;
  font-size: 1rem;
  background: transparent;
  box-shadow: 1px 1px 7px 1px rgba(0, 0, 0, 0.2);
  z-index: 5000 !important;
}

.k-dialog {
  z-index: 10500 !important;
}

.k-dialog-titlebar,
.k-window-titlebar {
  background-color: $primary_color;
  border-radius: 4px 4px 0 0;
  border: 0px;
}

.k-dialog-content,
.k-window-content {
  white-space: pre-wrap; // Allow new lines and spaces in dialog popup
  background: white;
  border-radius: 4px 4px 0 0;
}

.k-dialog-titlebar + .k-dialog-content,
.k-window-titlebar + .k-window-content {
  border-radius: 0px;
  overflow: auto;
  overflow: overlay;
}

.k-button {
  color: $primary_color;

  &.k-primary {
    color: $primary_color;
    border-color: $primary_color;
    background: $primary_color;
    color: white;

    &:hover {
      color: unset;
      border-color: unset;
      background-color: unset;
      background-image: unset;
    }

    &:focus {
      box-shadow: inset 0 0 0 2px #51738b3b !important;
    }
  }
}

.k-dialog-buttongroup,
.k-window-buttongroup {
  gap: 0.5rem;
  background: white;
  border-radius: 0 0 4px 4px;

  .k-button {
    @extend .btn_normal, .btn_wide;

    color: unset;
    background-image: none;
    margin: 0 !important;

    &[aria-label='No'],
    &[aria-label='Discard'],
    &[aria-label='Cancel'],
    &[aria-label='Copy to Clipboard'] {
      @extend .btn_negative;
    }

    &[aria-label='Yes'],
    &[aria-label='Save'],
    &[aria-label='Ok'] {
      @extend .btn_blue;
    }
  }
}

// Switch
.k-switch-on .k-switch-container,
.k-switch-on:hover .k-switch-container,
.k-switch-on.k-state-hover .k-switch-container,
.k-list .k-item:hover.k-state-selected,
.k-list .k-item.k-state-hover.k-state-selected,
.k-list-optionlabel:hover.k-state-selected,
.k-list-optionlabel.k-state-hover.k-state-selected,
.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected {
  background-color: $primary_color;
  color: #ffffff;
}

// Tab Strip
.k-tabstrip-items .k-item {
  color: $mid_grey;
  transition: color 0.35s ease, background 0.35s ease;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  background: white;
  font-weight: bolder;
  border-bottom: none;
  margin: 0.5px;
  box-sizing: border-box;

  &.k-state-active,
  &.k-state-selected {
    font-weight: bolder;
    box-shadow: inset 0 -4px 0px $primary_color;
    color: $black;
  }

  &:not(.k-state-active) {
    background: rgba(0, 0, 0, 0.025);
    color: #cbcbcb;

    &:hover {
      color: $black;
    }
  }
}

// Dropdowns
.k-combobox .k-dropdown-wrap,
.k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap {
  background-color: white;
  background-image: none;
  border-radius: 0.25rem;
  height: 2rem;
}

// TreeList
.k-grid {
  color: #2b495e;
  border-radius: 0.25rem;
  overflow: hidden;
  border-color: rgb(236 238 243);
}
.k-grid tbody tr:hover,
.k-grid tbody tr.k-state-hover {
  background-color: #2b495e14;
}
.k-checkbox:checked {
  border-color: $primary_color;
  background-color: $primary_color;
}
.k-checkbox:checked:focus {
  border-color: $primary_color;
  box-shadow: none;
}
.k-radio:checked {
  border-color: #2b495e;
  color: #527e9d;
  background-color: #ffffff;
}
.k-radio:checked:focus {
  border-color: #2b495e;
  box-shadow: 0 0 0 2px rgb(43 73 94 / 30%);
}
.k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  background-color: #2b495e2d;
}
.k-grid tr.k-alt {
  background-color: #f8f9fb;
}
.k-grid-header,
.k-grid-toolbar,
.k-grouping-header,
.k-grid-add-row,
.k-grid-footer,
.k-checkbox:indeterminate,
.k-checkbox.k-state-indeterminate {
  color: #2b495e;
  background-color: #eceef3;
}

// Grid Pagination (kendo-pager)
.k-pager-numbers .k-link {
  color: $primary_color;

  &.k-state-selected {
    color: $primary_color;
    background-color: #2b495e2d;
  }

  &:hover {
    color: $primary_color;
    background-color: #2b495e14;
  }
}

.k-tooltip {
  background-color: #5a7389;
}
