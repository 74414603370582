
.page_content_header {
    margin-bottom: auto;
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:flex-start;
    -webkit-align-items:flex-start;
    width: 100%;
    min-height:0;
    margin: 0px 0 10px; 


	.page_content_header_title {
		h1 {
			font-size: 1.5rem;
			line-height: 30px;
			font-weight: 400;
		}
	}

	.page_content_header_actions {
		>ul {
			margin:0;
			padding:0;
			list-style: none;
			font-size: 0;

			>li {
				display: inline-block;
				font-size: $font_size;
				margin-left: 10px;

				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
}




#page_pulldown_container {
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    justify-content: flex-start;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:center;
    -webkit-align-items:center;
    width: 100%;
    height: 100%;
    min-height:0;
    background-color: $lighter_grey;
    flex: 1 1 100%; 

	#page_pulldown {
		background-color: $white;
		width: 100%;
		@include box-sizing(border-box);
		flex: 0 1 200px; 
		@include box-shadow(0 5px 20px rgba($black,0.1));
		@include transition(all .3s ease);
		position: relative;
		z-index: 2;
	}

	#page_widgets {
		width: 100%;
		@include box-sizing(border-box);
		overflow:auto;
		flex: 1 1 auto;
		@include transition(all .3s ease);
	}

	&.show_details {

		#page_pulldown {
			flex: 0 1 auto; 
		}

		#page_widgets {
			flex: 1 1 auto;
		}
	}
}


#widget_chart_pulldown_trigger {
	margin:0 auto;
	text-align: center;
	position: absolute;
	left: 50%;
	bottom:-30px;
	@include translate(-50%,0);
	z-index: 1;
	@include box-shadow(0 10px 20px rgba($black,0.1));
}
