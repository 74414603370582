.message {
	background-color: $darker_grey;
	display: none;

	.message_container {
		display: flex;
	    flex-direction: row;
	    -webkit-flex-direction: row;
	    justify-content: space-between;
	    flex-wrap:nowrap;
	    -webkit-flex-wrap:nowrap;
	    align-items:center;
	    -webkit-align-items:center;
	    width: 100%;
	    min-height:0;
	    padding: 0px 0;
	    line-height: 20px;
	    @include border-radius(4px);
	    color: $white;
	    min-height: 70px;

		.message_icon {
			padding:15px;
			font-size: 30px;
			position: relative;

			.message_icon_main {
				margin-left: 5px;
				z-index: 1;
				position: relative;
			}

			.message_icon_shadow {
				position: absolute;
				left:15px;
				top:20px;
				z-index: 0;
				color: rgba($white,0.1);
			}
		}

		.message_details {
			padding:15px 0;
			display: flex;
		    flex-direction: row;
		    -webkit-flex-direction: row;
		    justify-content: space-between;
		    flex-wrap:no-wrap;
		    -webkit-flex-wrap:no-wrap;
		    align-items:center;
		    -webkit-align-items:center;
		    width: 100%;
		    min-height:0;
		    line-height: 20px;
		    flex:1 1 auto;


			.message_text {

				.message_title {
				}

				.message_description {
					@include opacity(0.5);

					p {
						line-height: 20px;
						&:last-of-type {
							margin-bottom: 0;
							padding-bottom: 0;
						}
					}
				}
			}

			.message_action {
				button {
					color: $white;
				}
			} 
		}

		.message_close {
			padding:15px;
			@include opacity(0.5);

			&:hover {
				@include opacity(1.0);
			}

			button {
				color: $white;
			}
		}
	}
}


.rotate {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    // @include translate(-50%, -50%);
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
    border:2px solid $white;
    @include box-sizing(border-box);
    @include border-radius(20px);

    .icon_loading {
    	height: 20px;
    	width: 20px;
    	font-size: 20px;
    	display: block;
    	vertical-align: top;
    	text-align: center;
    	line-height: 20px;
    }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }