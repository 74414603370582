.fadein {
  animation: fadein 0.35s;
}

.fadeinslow {
  animation: fadein 1s;
}

.fadeout {
  animation: fadeout 0.35s;
}

.slidein {
  animation: slidein 0.35s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slidein {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
