.chart_summary {
	display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:flex-end;
    -webkit-align-items:flex-end;
    margin-top: 20px;

    &.negative {
    	.chart_summary_details {
    		color: $red;
    	}
    }

	.chart_summary_details {
		color: $blue;
		.chart_summary_value {
			line-height: 30px;
			font-size: 1.8rem;
			font-weight: 600;

		}
		.chart_summary_budgeted {
			line-height: 20px;
			color: $mid_grey;
		}
	}

	.chart_summary_cta {}
}



.highchart_container {
	height: 100%;
	width:100%; 
	margin:0 auto;
	min-width: 0;
	overflow: hidden;

	&.auto_height {
		height: inherit;
	}
}


.line_color_symbol {
	display: inline-block;
	height: 8px;
	width: 8px;
	@include border-radius(20px);
}