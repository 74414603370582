/********** Base **********/

$base_color: #505050;
$body_background: #ffffff;
$font_pack: 'Source Sans Pro', Helvetica, sans-serif;
$font_size: 14px;
$app_color: $base_color;
$border_color: #dfe0e1;
$placeholder-color: rgba(#000000, 0.4);
$placeholder-color-white: #ffffff;
$input_background: #ffffff;
$input_background_readonly: transparent;
$input_border: #ababab;
$input_border_focus: #505050;
$input_border_expected: #ffcd40;
$input_required: rgba(#f8e71c, 0.3);

/********** Colours **********/

$primary_color: #51738b;

$black: #000000;
$white: #ffffff;

$grey_1: #4a4a4a;
$grey_2: #585d69;
$grey_3: #5d6978;

$lighter_grey: #eef1f6;
$light_grey: #eceef3;
$grey: #cccccc;
$mid_grey: #7e8c99;
$dark_grey: #525b6b;
$darker_grey: #465765;
$starling_black: #51738b;

$disabled_grey: #ababab;
$blue: #5e91dd;
$aqua: #33BFA5;
$teal: #3E6F7A;
$charcoal: #2d404d;
$navy: #2b495e;
$red: #d75363;
$green: #72bf44;
$orange: #f07d23;
$yellow: #ffcd40;

$ticker_up: #2be77a;
$ticker_down: #ef6627;

$state_hover: rgba($blue, 0.1);

$shadow_1: rgba($grey_1, 0.2);
$shadow_2: rgba($black, 0.2);

$hover: rgba($blue, 0.1);

$notification: #f5a623;

$group_color_1: #a7c5e5;
$group_color_2: #d39fe9;
$group_color_3: #e9e19f;
$group_color_4: #9a75ff;

$tree_btn_hover: #f4f6f7;

$alert_red: #d75363;

/********** Pages **********/

/********** Modal **********/
$modal_background: #ffffff;

/********** Button **********/
$btn_primary_bg: #72bf44;
$btn_primary_bg_hover: darken($btn_primary_bg, 10%);
$btn_primary_color: #ffffff;
$btn_primary_color_hover: #ffffff;

$btn_secondary_bg: #51738b;
$btn_secondary_bg_hover: darken($btn_secondary_bg, 10%);
$btn_secondary_color: #ffffff;
$btn_secondary_color_hover: #ffffff;

/******* Icons ********/

$x: -50%;
$y: -50%;

/******* Icons ********/

/******* Media Queries ********/

$mobile: '(min-width: 0px) and (max-width: 767px)';
$mobile_portrait: '(min-width: 0px) and (max-width: 767px) and (orientation: portrait)';
$mobile_landscape: '(min-width: 320px) and (max-width: 767px) and (orientation: landscape)';

$tablet: '(min-width: 768px) and (max-width: 1024px)';
$tablet_portrait: '(min-width: 768px) and (max-width: 1023px) and (orientation: portrait)';
$tablet_landscape: '(min-width: 1024px) and (max-width: 1279px)';

$desktop: '(min-width: 1025px) and (max-width: 1439px)';
$desktop_wide: '(min-width: 1440px)';
$desktop_extra_wide: '(min-width: 1680px)';
$min_desktop_wide: '(min-width: 1280px)';

$max_height: '(max-height: 760px)';
