@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?bn0vb1') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?bn0vb1') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?bn0vb1##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon_"], [class*=" icon_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon_tree { /* This is not included in frotend project */
  &:before {
    content: $icon_tree;
  }
}
.icon_transpose {
  &:before {
    content: $icon_transpose; 
  }
}
.icon_triangle_down {
  &:before {
    content: $icon_triangle_down; 
  }
}
.icon_triangle_up {
  &:before {
    content: $icon_triangle_up; 
  }
}
.icon_products {
  &:before {
    content: $icon_products; 
  }
}
.icon_asset_liability_management {
  &:before {
    content: $icon_asset_liability_management; 
  }
}
.icon_assumptions {
  &:before {
    content: $icon_assumptions; 
  }
}
.icon_chevron_down {
  &:before {
    content: $icon_chevron_down; 
  }
}
.icon_chevron_left {
  &:before {
    content: $icon_chevron_left; 
  }
}
.icon_chevron_right {
  &:before {
    content: $icon_chevron_right; 
  }
}
.icon_chevron_up {
  &:before {
    content: $icon_chevron_up; 
  }
}
.icon_close {
  &:before {
    content: $icon_close; 
  }
}
.icon_current_expected_credit_loss {
  &:before {
    content: $icon_current_expected_credit_loss; 
  }
}
.icon_data_set {
  &:before {
    content: $icon_data_set; 
  }
}
.icon_delete {
  &:before {
    content: $icon_delete; 
  }
}
.icon_edit {
  &:before {
    content: $icon_edit; 
  }
}
.icon_error {
  &:before {
    content: $icon_error; 
  }
}
.icon_funds_transfer_pricing {
  &:before {
    content: $icon_funds_transfer_pricing; 
  }
}
.icon_grid {
  &:before {
    content: $icon_grid; 
  }
}
.icon_ifrs {
  &:before {
    content: $icon_ifrs; 
  }
}
.icon_liquidity_modeling {
  &:before {
    content: $icon_liquidity_modeling; 
  }
}
.icon_lock {
  &:before {
    content: $icon_lock; 
  }
}
.icon_logo_empyrean {
  &:before {
    content: $icon_logo_empyrean; 
  }
}
.icon_msg_error {
  &:before {
    content: $icon_msg_error; 
  }
}
.icon_msg_success {
  &:before {
    content: $icon_msg_success; 
  }
}
.icon_notification {
  &:before {
    content: $icon_notification; 
  }
}
.icon_plus {
  &:before {
    content: $icon_plus; 
  }
}
.icon_quicklinks {
  &:before {
    content: $icon_quicklinks; 
  }
}
.icon_result_set {
  &:before {
    content: $icon_result_set; 
  }
}
.icon_sheet_loader {
  &:before {
    content: $icon_sheet_loader; 
  }
}
.icon_spinner {
  &:before {
    content: $icon_spinner; 
  }
}
.icon_stochastic_income_simulation {
  &:before {
    content: $icon_stochastic_income_simulation; 
  }
}
.icon_stress_testing {
  &:before {
    content: $icon_stress_testing; 
  }
}
.icon_summary {
  &:before {
    content: $icon_summary; 
  }
}
.icon_toggle_drawer_open {
  &:before {
    content: $icon_toggle_drawer_open; 
  }
}
.icon_toggle_drawer {
  &:before {
    content: $icon_toggle_drawer; 
  }
}
.icon_trash {
  &:before {
    content: $icon_trash; 
  }
}
.icon_triangle_left {
  &:before {
    content: $icon_triangle_left; 
  }
}
.icon_triangle_right {
  &:before {
    content: $icon_triangle_right; 
  }
}
.icon_undelete {
  &:before {
    content: $icon_undelete; 
  }
}
.icon_upload {
  &:before {
    content: $icon_upload; 
  }
}
.icon_user {
  &:before {
    content: $icon_user; 
  }
}
.icon_valid {
  &:before {
    content: $icon_valid; 
  }
}
.icon_warning {
  &:before {
    content: $icon_warning; 
  }
}
.icon_dropdown {
  &:before {
    content: $icon_dropdown; 
  }
}

