.flexarea_wrapper {
  background-color: #fff;
  color: #444;
  /* Use flexbox */
  display: flex;

  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: flex-start;
  flex-wrap:nowrap;
  -webkit-flex-wrap:nowrap;


  align-items:stretch; //stretch
  -webkit-align-items:stretch; //stretch
  width: 100%;
  height: 100%;
  min-height:0;
  padding: 0px 0;

  &.has_action_bar {
    height: calc(100% - 50px);
  }


}

.flexarea_box {
  padding: 10px;
  @include box-sizing(border-box);
  
  /* Use box-sizing so that element's outerwidth will match width property */
  box-sizing: border-box;
  
  /* Allow box to grow and shrink, and ensure they are all equally sized */
  flex: 1 1 auto;
  overflow: auto;
  position: relative;
}

.flexarea_handler {
  height: 10px;
  padding: 0;
  cursor: ns-resize;
  flex: 0 0 auto;
  @include box-shadow(0 0px 20px rgba($black,0.1));
  @include transition(all .3s ease);
  z-index: 1;
  position: relative;
  background: $white;

  &:before {
    content: '';
    display: block;
    height: 4px;
    width: 40px;
    background-color: rgba($starling_black,0.3);
    @include border-radius(10px);
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -2px;
    @include transition(all .3s ease);
  }

  &:hover {
  @include box-shadow(0 0px 20px rgba($black,0.2));
    &:before {
    background-color: rgba($starling_black,1.0);
    }
  }
}