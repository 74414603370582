#header {
    background-color: $darker_grey;
    display: none;
}

#header_inner {
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:flex-start;
    -webkit-align-items:flex-start;
    width: 100%;
    height: 100%;
    min-height:0;
    color: $white;
    
    #header_logo {
        padding:10px 0;
        line-height: 30px;
        margin-right: 20px;

        #header_logo_icon {
            float: left;

            a {
                display: block;
                color: $white;
                text-decoration: none;
                line-height: 30px;
                font-size: 30px;
            }
        }

        #header_logo_product {
            float: left;
            margin-left: 10px;

            span {
                display: block;
                letter-spacing: 0.1rem;
                text-transform: uppercase;
                color: $white;
            }
        }

    }

    #header_actions {
        margin-left: auto;
        padding:10px 0;
    }

    #header_quick_links {
        padding:10px 0;
        margin-left: 30px;
    }

    #header_user {
        padding:10px 0;
    }
}




ul#application_list {
    width: 100%;
    float: left;
    margin:0;
    padding:0;
    list-style: none; 

    >li {
        float: left;
        width: calc(100% / 3);
        text-align: center;

        a.application_link {
            display: block;
            text-decoration: none;
            @include border-radius(4px);
            text-align: center;
            padding: 10px 4px;
            color: inherit;

            &:hover, &:focus {
                background-color: rgba($primary_color,0.1);

                .application_icon {
                    background-color: rgba($primary_color,0.3);
                    color: $primary_color;
                }

                .application_name {
                    color: $primary_color;
                }
            }

            .application_icon {
                display: block;
                padding: 10px;
                @include border-radius(50px);
                background-color: rgba($black,0.05);
                width: 50px;
                @include box-sizing (border-box);
                margin: 0 auto 10px;

                span {
                    line-height: 30px;
                    width: 30px;
                    text-align: center;
                    font-size: 24px;
                    display: block;
                    vertical-align: top;
                }
            }

            .application_name {
                text-align: center;
                display: block;
                line-height: 15px;
                min-height: 30px;
                font-size: 0.8rem;
            }
        }
    }
}
