#action_bar {
	background-color: $lighter_grey;
    // @include box-shadow(0 4px 20px rgba($black,0.1));
    position: relative;
    z-index: 3;
}


#action_bar_inner {
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: flex-start;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:flex-start;
    -webkit-align-items:flex-start;
    width: 100%;
    min-height:0;
    color: $white;


	#product_selector {
		border-right:1px solid $border_color;
	}

	#view_selector {}	
}



.content_action_bar {
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:flex-start;
    -webkit-align-items:flex-start;
    width: 100%;
    min-height:0;
    border-bottom: 1px solid $border_color;
    padding: 10px;
    @include box-sizing(border-box);

    .content_action_bar_left {
        >ul {

        }
    }
    .content_action_bar_right {} 
}