button.btn_dropdown {
  border: none;
  text-align: left;
  border-color: $input_border;

  &.btn_secondary {
    // border-color: $input_border;
  }

  // This is to allow the click event to be passed through to the button
  .btn_text,
  .btn_icon_arrow,
  .btn_icon,
  .pkg_icon,
  .pkg_name,
  .btn_indicator,
  .btn_helper {
    pointer-events: none;

    span,
    div {
      pointer-events: none;
    }
  }

  .btn_indicator {
  }

  &:active {
    .btn_indicator {
      // transform: rotate(180deg);
    }
  }
}

.dropdown_container {
  position: relative;
  display: inline-block;

  &.full_width {
    display: block;
  }

  &.inline {
    display: inline-block;
    margin-left: 10px;
    vertical-align: bottom;
  }
}

/* Dropdown Content (Hidden by Default) */
.dropdown_content {
  display: none;
  min-width: 180px;
  top: calc(100% + 10px);
  background: $white;
  padding: 0px;
  @include border-radius(4px);
  box-shadow: 0 1px 4px rgba($black, 0.3), 0 5px 20px rgba($black, 0.1);
  z-index: 5;
  @include box-sizing(border-box);
  max-height: calc(50vh + 50px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  width: 320px;
  z-index: 999;
  color: $app_color;
  text-align: left;

  &.no_scroll_bar {
    max-height: inherit;
  }

  &.has_padding {
    padding: 15px;
  }

  &.position_right {
    right: 0;
  }

  // &.position_top {
  //     top: auto;
  //     bottom:-100%;
  // }

  &.full_width {
    left: 0;
    right: 0;
    min-width: inherit;
  }

  &.min_width {
    min-width: 320px;
  }

  .dropdown_content_body {
    padding: 15px;

    &.no_padding {
      padding: 0;
    }

    p {
      &:last-of-type {
        margin: 0;
        padding: 0;
      }
    }
  }

  .dropdown_footer {
    display: block;
    padding: 15px;
    border-top: 1px solid $border_color;

    button {
      width: 100%;
    }

    &.align_center {
      text-align: center;
    }
  }
}

/* Show the dropdown menu (use JS to add this class to the .dropdown_content container when the user clicks on the dropdown button) */
.dropdown_content.show {
  display: block;
}

ul.menu_list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: block;

    > ul {
      margin: 0;
      padding: 0;
      list-style: none;

      > li {
        display: block;

        a {
          padding: 5px 10px 5px 20px;

          &:before {
            content: '- ';
          }
        }
      }
    }

    a {
      display: block;
      padding: 10px 10px;
      line-height: 20px;
      text-decoration: none;
      color: inherit;

      &:hover,
      &:focus {
        color: $primary_color;
        background-color: rgba($primary_color, 0.1);
      }
    }

    .menu_divider {
      display: block;
      margin: 0;
      padding: 0;
      border-top: 1px solid $border_color;

      span {
        display: block;
        line-height: 20px;
        font-weight: 600;
        font-size: 0.8rem;
        padding: 5px 10px;
      }
    }
  }
}
