form {
  margin: 0;
  padding: 0;
}

input,
select,
button {
  font-size: $font_size; // 1.0em;
  font-family: $font_pack !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

textarea {
  font-size: $font_size; // 1.0em;
  font-family: $font_pack !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

label {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;

  legend {
    display: block;
    box-sizing: border-box;
    line-height: 20px;
    padding: 5px 0 5px 0px;

    &.hidden_legend {
      position: absolute;
      font-size: 0;
    }
  }
}

/* Text Area */
textarea.inputbox {
  width: 100%;
  resize: none;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  border: 1px solid $input_border;
  @include box-sizing(border-box);
  margin: 0;
  padding: 5px 5px;
  line-height: 20px;
  vertical-align: bottom;
  background: $input_background none 0 0 no-repeat;
  @include border-radius(4px);
  color: $app_color;

  &.no_border {
    border: 1px solid transparent;
  }

  &[readonly] {
    border: 1px solid $grey;
    background-color: $input_background_readonly;
    padding-right: 25px;
  }

  &:focus {
    border: 1px solid $input_border_focus;

    &[readonly] {
      border: 1px solid $lighter_grey;
    }

    &[readonly][disabled] {
      border: 1px solid transparent;
    }
  }

  &.auto_width {
    width: initial;
  }
}

@element 'textarea.inputbox.required' and (max-characters: 0) {
  eq_this {
    /* input:empty */
    background-color: $input_required !important;
  }
}

@element 'textarea.inputbox.required' and (min-characters: 1) {
  eq_this {
    /* input:not(:empty) */
    background-color: $white !important;
  }
}

/* Inputbox */
input.inputbox {
  width: 100%;
  line-height: 20px;
  margin: 0;
  padding: 4px 5px;
  border: 1px solid $input_border;
  -webkit-appearance: none;
  -moz-appearance: none;
  @include box-sizing(border-box);
  background: $input_background none 0 0 no-repeat;
  -moz-background-size: 0% auto;
  text-indent: 0.01px;
  text-overflow: '';
  @include border-radius(4px);
  color: $app_color;

  &.no_border {
    border: 1px solid transparent;
  }

  &[readonly] {
    border: 1px solid transparent;
    background-color: $input_background_readonly;

    &.datepicker {
      border: 1px solid $input_border;
      background-color: $input_background;
    }
  }

  &:focus {
    border: 1px solid $input_border_focus;
    // box-shadow: 0 0 4px 1px $input_border_focus;

    &[readonly] {
      border: 1px solid $input_border;
    }

    &[readonly][disabled] {
      border: 1px solid transparent;
    }
  }

  &.auto_width {
    width: initial;
  }

  &.padding_large {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;

    &.searchbox {
      padding-left: 40px;
      background: $input_background url('^assets/images/search.png') no-repeat;
      @include image-2x('^assets/images/search@2x.png', 20px, 20px);
      background-position: left 15px center;
      background-color: $input_background !important;

      &.no_border {
        border: 0px solid !important;
      }
    }
  }

  &.padding_medium {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;

    &.searchbox {
      padding-left: 40px;
      background: $input_background url('^assets/images/search.png') no-repeat;
      @include image-2x('^assets/images/search@2x.png', 20px, 20px);
      background-position: left 15px center;
      background-color: $input_background !important;

      &.no_border {
        border: 0px solid !important;
      }
    }
  }

  &.searchbox {
    background: $input_background url('^assets/images/search.png') no-repeat;
    @include image-2x('^assets/images/search@2x.png', 20px, 20px);
    background-position: left 5px center;
    background-color: $input_background !important;
    padding-left: 30px;
    min-width: 240px;

    &.no_border {
      border: 0px solid !important;
    }
  }
}

input[type='date'] {
  -webkit-appearance: none;
}

input[type='number'] {
  -webkit-appearance: none;
}

@element 'input.inputbox.required' and (max-characters: 0) {
  eq_this {
    /* input:empty */
    background-color: $input_required !important;
  }
}

@element 'input.inputbox.required' and (min-characters: 1) {
  eq_this {
    /* input:not(:empty) */
    background-color: $white !important;
  }
}

/* Select */
select.inputbox {
  width: 100%;
  line-height: 20px;
  margin: 0;
  padding: 4px 25px 4px 10px;
  border: 1px solid $input_border;
  color: $app_color;
  -webkit-appearance: none;
  -moz-appearance: none;
  @include box-sizing(border-box);

  background: $input_background url('^assets/images/select_dropdown.png') no-repeat;
  @include image-2x('^assets/images/select_dropdown@2x.png', 20px, 20px);

  background-position: right center;

  -webkit-padding-end: 25px;
  -moz-padding-end: 25px;
  -moz-background-size: 0% auto;
  text-indent: 0.01px;
  text-overflow: '';
  padding-right: 25px\9;
  cursor: pointer;
  @include border-radius(4px);

  &.no_border {
    border: 0px solid transparent;
    padding: 5px 25px 5px 10px;

    &.inputbox_small {
      padding: 0 25px 0 0;
    }

    &:focus {
      border: 0px solid $input_border_focus;
      @include opacity(1);
    }
  }

  &.no_dropdown {
    background: none !important;
    background-color: $input_background !important;
    padding: 10px !important;
  }

  &.add_item_select_box {
    @include opacity(0.5);
  }

  &.inputbox_filter {
    border-color: $app_color !important;
  }

  &[readonly] {
    border: 1px solid $grey;
    background-color: $input_background_readonly;
  }

  &.no_dropdown {
    -webkit-padding-end: 0px;
    -moz-padding-end: 0px;
    padding-right: 0px\9;
    padding: 10px 0px 9px 0px;
  }

  &:focus {
    border: 1px solid $input_border_focus;
    @include opacity(1);
  }

  &.auto_width {
    width: initial;
  }

  &.reversed {
    background-color: transparent;
    color: inherit;
    background: transparent url('^assets/images/select_dropdown_white.png') no-repeat;
    @include image-2x('^assets/images/select_dropdown_white@2x.png', 20px, 20px);
    background-position: right center;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &.no_padding {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  option {
    color: $app_color;

    &[disabled] {
      color: $grey;
    }
  }
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

select::-ms-expand {
  display: none;
  text-indent: 0.01px;
  text-overflow: '';
}

select::-moz-focus-inner {
  padding: 0;
  border: 0;
  margin: 0px;
  background-image: none !important;
}

// Style CSS for Firefox Only
@-moz-document url-prefix() {
  select.inputbox {
    padding: 6px 25px 7px 5px;
  }
}

// Use EQCSS to determin if there is a value in the select - This will most likely always show a complete state
@element 'select.inputbox.required' and (max-characters: 0) {
  eq_this {
    /* input:empty */
    background-color: $input_required !important;
  }
}

@element 'select.inputbox.required' and (min-characters: 1) {
  eq_this {
    /* input:not(:empty) */
    background-color: $white !important;
  }
}

/* Disabled States */

input[disabled],
textarea[disabled],
select[disabled] {
  -webkit-text-fill-color: $grey;
  -webkit-opacity: 1;
  color: $grey;
  background: transparent;
}

input.inputbox[disabled] {
  background-color: $lighter_grey;
  border: 1px solid $lighter_grey;
  color: $grey;
  cursor: default;
}

select.inputbox[disabled] {
  background-color: $lighter_grey;
  border: 1px solid $lighter_grey;
  color: $grey;
  cursor: default;
}

textarea[disabled] {
  background-color: $lighter_grey;
  border: 1px solid $lighter_grey;
  color: $grey;
  cursor: default;
}

/* Placeholder Text */
.inputbox {
  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: $placeholder-color;
    // -webkit-text-fill-color: transparent !important;
    opacity: 1;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $placeholder-color;
    opacity: 1;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $placeholder-color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $placeholder-color;
    opacity: 1;
  }
  &:placeholder-shown {
    /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
    color: $placeholder-color;
    opacity: 1;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

/* Error States */

/* Inputbox Widths */

input.inputbox,
select.inputbox,
textarea.inputbox {
  &.width_x-small {
    width: 60px;
    display: inline-block;
  }

  &.width_small {
    width: 80px;
    display: inline-block;
  }
}

.inputbox.invalid {
  border-color: $red;
}

.invalid_message {
  display: inline-block;
  line-height: 20px;
  clear: both;
  color: $red;
}

// IE 11 Overrides

@media all and (-ms-high-contrast: none) {
  input.inputbox {
    height: 30px;
  } /* IE10 */
  *::-ms-backdrop,
  input.inputbox {
    height: 30px;
  } /* IE11 */

  select.inputbox {
    height: 30px;
  } /* IE10 */
  *::-ms-backdrop,
  select.inputbox {
    height: 30px;
  } /* IE11 */

  textarea.inputbox[readonly] {
    overflow: auto;
    -ms-overflow-style: none;
  }
  *::-ms-backdrop,
  textarea.inputbox[readonly] {
    overflow: auto;
    -ms-overflow-style: none;
  } /* IE11 */
}
