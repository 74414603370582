#widgets_inner {
	background-color: white;
}


.widget_group {
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: flex-start;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:stretch; //stretch
    -webkit-align-items:stretch; //stretch
    width: 100%;
    min-height:0;
    padding: 0px 0;
    // margin-left: -10px;
    // margin-right: -10px;
    // margin-bottom: 20px;

    &.fixed_height {
    	height: 220px;
    }

	.widget_container {
		margin: 10px;
		flex:1 1 100%;
		@include box-sizing(border-box);
		padding: 0px;
		background-color: $white;
		@include border-radius(5px);
		@include box-shadow(0 5px 20px rgba(0,0,0,0.1));
		min-width: 0;

		&.one_third {
			flex:0 1 calc(100% / 3);
		}

		&.two_third {
			flex:0 1 calc(calc(100% / 3) * 2);
		}

		&.half {
			flex:0 1 50%;
		}

		&.quarter {
			flex:0 1 25%;
		}

		&.three_quarter {
			flex:0 1 75%;
		}

		&.negative {
			.widget_header {
				.widget_title {
					h3 {
						color: $red;
					}
				}
			}
		}


		.widget_inner {
			// margin:0px 10px 20px;
			@include box-sizing(border-box);
			position: relative;
			// min-height: 160px;
		}

		.widget_header {
			display: flex;
		    flex-direction: row;
		    -webkit-flex-direction: row;
		    justify-content: space-between;
		    flex-wrap:nowrap;
		    -webkit-flex-wrap:nowrap;
		    align-items:flex-start;
		    -webkit-align-items:flex-start;
		    width: 100%;
		    min-height:0;
		    padding: 20px 20px 10px;
		    @include box-sizing(border-box);


			.widget_title {
				h3 {
					font-size: 1.25rem; //20px
					line-height: 30px;
					font-weight: 600;
				}
			}

			.widget_actions {
				>ul {
					margin:0;
					list-style: none;
					padding:0;
					font-size: 0;

					>li {
						display: inline-block;
						font-size: $font_size;
						margin-left: 10px; 
						line-height: 30px;
					}
				}
			}
		}

		.widget_content {
			position: relative;
			padding: 10px  20px 20px;
			min-width: 0;

			.widget_content_icon {
				position: absolute;
				right: 0;
				top: 0;
				font-size: 60px;
				width: 60px;
				margin:0px auto 10px;

				&.wide {
					font-size: 70px;
					width: 70px;
				}
				
				.widget_content_icon_main {
					z-index: 1;
					position: relative;
				}

				.widget_content_icon_shadow {
					position: absolute;
					left: -5px;
					bottom: -5px;
					z-index: 0;
				}
			}
		}

		.widget_footer {
			margin-top: 10px;
			line-height: 20px;
			padding: 10px 20px 20px;
		}

		

		
	}	
}










#widget_container {
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:flex-start;
    -webkit-align-items:flex-start;
    width: 100%;
    min-height:0;


	.widget_label {
		padding-top: 210px;
		flex:0 0 100px;

		.label_group {
			margin-top: 20px; 
			display: none;
			
			.label_data {
				line-height: 20px;
				padding: 5px 0;

				&.actual {
					color: $mid_grey;
				}
				&.budget {}
				&.variance {
					border-top:1px solid $border_color;
					font-weight: 600;
				}

				&.positive {
					color: $green;
				}
				&.negative {
					color: $red;
				}	
			}
		}
	}

	.widget_chart_container {
		overflow: auto;
		flex: 1 1 100%;


	    display: flex;
	    flex-direction: row;
	    -webkit-flex-direction: row;
	    justify-content: space-between;
	    flex-wrap:nowrap;
	    -webkit-flex-wrap:nowrap;
	    align-items:flex-start;
	    -webkit-align-items:flex-start;
	    // width: 100%;
	    min-width: 0;


		.widget_chart {
			flex:1 0 auto;
			min-width: 180px;
			min-width: 0;

			.chart_donut {
				position: relative;
				padding: 20px;

				.chart_remove {
					position: absolute;
					right: 10px;
					top: 10px;

					button {
						@include opacity(0.2);

						&:hover {
							@include opacity(1.0);
						}
					}
				}

				.chart_container {
					height: 140px;
					width: 140px;
					// background-color: $white;
					@include box-sizing(border-box);
					@include border-radius(200px);
					position: relative;
					margin:0 auto;
					border:0px solid $lighter_grey;

					.donut_data_container {
						position: absolute;
						left: 50%;
						top: 50%;
						@include translate(-50%, -50%); 

						.donut_data {
							display: block;
							text-align: center;
							line-height: 20px;
							white-space: nowrap;

							&.actual {
								font-size: 1.0rem;
								font-weight: 600;
							}

							&.budget {
								color: $mid_grey;
								font-size: 0.8rem;

								&:before {
									content: '/ ';
								}
							}

							&.negative {color: $red;}
							&.positive {color: $green;}
						}

					}

					.donut_chart_container {
						position: absolute;
						left:-10px;
						top: -10px;
						width: 160px;
						height: 160px;

						
					}
				}
				.chart_title_container {
					margin-top: 10px;

					h3.chart_title {
						text-align: center;
						line-height: 20px;
						font-size: 0.8rem;
						text-transform: uppercase;
						font-weight: 600;
					}
					.chart_desc {
						text-align: center;
						line-height: 20px;
						font-size: $font-size;
						color: $dark_grey;
					}
				}
			}

			.chart_details {
				padding: 0 0 20px;
				text-align: center;
				display: none;

				.chart_data_group {
					margin-top: 20px; 

					&:first-of-type {
						margin-top: 0;
					}

					.chart_data {
						line-height: 20px;
						padding: 5px 0;
						
						&.actual {
							color: $mid_grey;
						}
						&.budget {}
						&.variance {
							border-top:1px solid $border_color;
							font-weight: 600;
						}

						&.positive {
							color: $green;
						}
						&.negative {
							color: $red;
						}	
					}
				}
			}
		}
	}

	.widget_chart_adder {
		flex: 1 1 auto;

		.btn_add_summary {
			width:auto;
			background-color:transparent;
			margin:20px;
			padding:0;
			overflow:visible;
			-webkit-appearance:none;
			box-sizing:border-box;
			cursor:pointer;
			border:0px solid;
			display: block;

			.btn_summary_icon {
				@include border-radius(200px);
				display: block;
				height: 120px;
				width: 120px;
				border:4px dashed $grey;
				position: relative;
				@include box-sizing(border-box);
				background-color: transparent;
				margin: 10px 10px 20px 10px;

				span {
					position: absolute;
					left: 50%;
					top: 50%;
					@include translate(-50%, -50%); 
					font-size: 30px;
					display: block;
					color: $grey;
				}
			}

			.btn_summary_text {
				margin-top: 10px;
				text-align: center;
				line-height: 20px;
				font-size: 0.8rem;
				text-transform: uppercase;
				font-weight: 600;
				color: $grey;
			}

			&:hover, &:focus {
				.btn_summary_icon {
					border-color: $primary_color;

					span {
						color: $primary_color;
					}
				}

				.btn_summary_text {
					color: $primary_color;
				}
			}

		}

	}
}

