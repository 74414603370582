/** Flex layout kit by Chris Buckley */

/* makes a flex container */
.flex-container{
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    max-height:100%;
    overflow: hidden;
}

/* modifiers */
.full-height {
    height: 100%;
}
.full-width {
    width: 100%;
}
.scrollable {
    overflow-y: auto;
}
.no-overflow {
    overflow: hidden;
}
.horizontal {
    flex-direction: row;
}
.vertical {
    flex-direction: column;
}
.wrap {
    flex-wrap: wrap;
}
.centered {
    align-items: center;
    justify-content: center;
}
.vertical.left {
    align-items: flex-start;
    justify-content: center;
}
.vertical.top.centered {
    justify-content: start;
    align-items: middle;
}
.vertical.bottom {
    justify-content: end;
    align-items: middle;
}
.vertical.left.top {
    justify-content: start;
}
.vertical.left.bottom {
    justify-content: end;
}
.vertical.right {
    align-items: flex-end;
    justify-content: center;
}
.horizontal.left {
    align-items: center;
    justify-content: flex-start;
}
.horizontal.right {
    align-items: center;
    justify-content: flex-end;
}
.horizontal.top {
    align-items: flex-start;
    justify-content: center;
}
.horizontal.top.left {
    align-items: flex-start;
    justify-content: start;
}
.horizontal.top.right {
    align-items: flex-start;
    justify-content: end;
}
.horizontal.bottom {
    align-items: flex-end;
    justify-content: center;
}
.horizontal.bottom.left {
    align-items: flex-end;
    justify-content: start;
}
.horizontal.bottom.right {
    align-items: flex-end;
    justify-content: end;
}
.middle {
    align-items: center;
}
.right {
    align-items: center;
    justify-content: right;
}
.left {
    align-items: center;
    justify-content: left;
}
.space-around {
    justify-content: space-around;
}
.space-between {
    justify-content: space-between;
}
.space-evenly {
    justify-content: space-evenly;
}

/** Flex items */
.flex-grow {
    /* flex: 1 1 auto; */
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: auto;
    max-height:100%;
}
.flex-shrink {
    flex-shrink: 1;
    flex-basis: auto;
    min-width: 0;
}
.flex-fixed {
    /* flex: 0 0 auto; */
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: auto;
}
