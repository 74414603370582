input,
button,
textarea {
  font-size: $font_size;
  font-family: $font_pack !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.btn_normal {
  width: auto;
  background-color: transparent;
  position: relative;
  color: $app_color;
  line-height: 20px;
  margin: 0px;
  padding: 5px 10px;
  overflow: visible;
  @include border-radius(4px);
  -webkit-appearance: none;
  box-sizing: border-box;
  display: inline-block;
  border: 0px solid;
  cursor: pointer;
  // @include transition(all .3s ease);
  min-width: 20px;
  @include box-sizing(border-box);
  font-size: $font_size;

  &.btn_inverse {
    color: $white;

    &:hover,
    &:focus {
      // @include box-shadow(0px 0px 1px rgba($black,0.5));
      color: darken($white, 10%);
    }
  }

  &:hover,
  &:focus {
    // @include box-shadow(0px 0px 1px rgba($black,0.5));
    color: $black;
  }

  &.btn_small {
    font-size: 10px;
  }

  &:active {
  }

  &:disabled {
    color: $grey !important;
    cursor: default !important;
    background-color: rgba($black, 0.05) !important;
    border: 0px solid !important;
    padding: 5px 10px;

    &:hover,
    &:focus {
      border-color: rgba($black, 0.05) !important;
      background-color: rgba($black, 0.05) !important;
      color: $grey !important;
    }
  }

  &.btn_primary {
    background-color: $primary_color;
    color: $white;

    &:focus,
    &:hover {
      background-color: darken($primary_color, 0.2);
      color: $white;
    }

    &:disabled {
      color: $grey !important;
      cursor: default !important;
      background-color: rgba($black, 0.05) !important;

      &:hover,
      &:focus {
        background-color: rgba($black, 0.05) !important;
        color: $grey !important;
      }
    }
  }

  &.btn_negative {
    background-color: transparent;
    color: $navy;
    padding: 4px calc(1rem - 1px);
    border: 1px solid $navy;
    @include transition(all 0s ease);

    &:focus,
    &:hover {
      background-color: $navy;
      color: $white;
      border: 0px solid $navy;
      padding: 5px 1rem;
    }

    &:disabled {
      color: $grey !important;
      cursor: default !important;
      background-color: rgba($lighter_grey, 0.2) !important;
      border: 1px transparent solid !important;
      padding: 5px 1rem;
    }

    &.no_focus {
      &:focus {
        background-color: transparent;
        color: $navy;
        padding: 4px calc(1rem - 1px);
        border: 1px solid $navy;
      }
    }
  }

  &.btn_green {
    background-color: $btn_primary_bg;
    color: $white;

    &:focus,
    &:hover {
      background-color: $btn_primary_bg_hover;
      color: $white;
    }

    &:disabled {
      color: $grey !important;
      cursor: default !important;
      background-color: rgba($black, 0.05) !important;

      &:hover,
      &:focus {
        background-color: rgba($black, 0.05) !important;
        color: $grey !important;
      }
    }
  }

  &.btn_black {
    background-color: $white;
    color: $app_color;

    &:hover {
      background-color: $white;
      color: $green;
    }

    &:disabled {
      color: $grey !important;
      cursor: default !important;
      background-color: rgba($black, 0.05) !important;

      &:hover,
      &:focus {
        background-color: rgba($black, 0.05) !important;
        color: $grey !important;
      }
    }
  }

  &.btn_blue {
    background-color: $btn_secondary_bg;
    color: $btn_secondary_color;

    &:focus,
    &:hover {
      background-color: $btn_secondary_bg_hover;
      color: $btn_secondary_color_hover;
    }

    &:disabled {
      color: $grey !important;
      cursor: default !important;
      background-color: rgba($lighter_grey, 0.2) !important;

      &:hover,
      &:focus {
        background-color: rgba($lighter_grey, 0.2) !important;
        color: $grey !important;
      }
    }
  }

  &.btn_white {
    background-color: $white;
    color: $green;
    border: 1px solid $green;
    padding-top: 4px;
    padding-bottom: 4px;

    &:focus,
    &:hover {
      background-color: $green;
      color: $white;
    }

    &:disabled {
      color: $grey !important;
      cursor: default !important;
      background-color: rgba($black, 0.05) !important;

      &:hover,
      &:focus {
        background-color: rgba($black, 0.05) !important;
        color: $grey !important;
      }
    }
  }

  &.btn_outline_white {
    background-color: transparent;
    color: $white;
    border: 1px solid $white;
    padding-top: 4px;
    padding-bottom: 4px;

    &:focus,
    &:hover {
      background-color: $white;
      color: $app_color;
    }

    &:disabled {
      color: $grey !important;
      cursor: default !important;
      background-color: rgba($black, 0.05) !important;

      &:hover,
      &:focus {
        background-color: rgba($black, 0.05) !important;
        color: $grey !important;
      }
    }
  }

  &.btn_grey {
  }

  &.btn_red {
    background-color: $red;
    color: white;

    &:hover,
    &:focus {
      background-color: #d73a3a;
      color: white;
    }

    &:disabled {
      color: $grey !important;
      cursor: default !important;
      background-color: rgba($red, 0.05) !important;

      &:hover,
      &:focus {
        background-color: rgba($red, 0.05) !important;
        color: $grey !important;
      }
    }
  }

  &.btn_orange {
    background-color: $orange;
    color: white;

    &:hover,
    &:focus {
      background-color: rgb(240, 141, 35);
      color: white;
    }

    &:disabled {
      color: $grey !important;
      cursor: default !important;
      background-color: rgba($orange, 0.05) !important;

      &:hover,
      &:focus {
        background-color: rgba($orange, 0.05) !important;
        color: $grey !important;
      }
    }
  }

  &.btn_no_border {
    border: 0 solid !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &.btn_no_padding {
    padding: 4px 0px;

    &.btn_no_border {
      padding: 5px 0;

      &.btn_table {
        padding: 0 0;
        vertical-align: text-top;
      }
    }
  }

  &.btn_icon_only {
    padding-left: 5px;
    padding-right: 5px;

    > .btn_text {
      position: absolute;
      font-size: 0;
    }

    > .btn_icon {
      display: block;

      span {
        display: block;
        font-size: 20px;
        line-height: 20px;
        width: 20px;
        text-align: center;
      }
    }

    &.btn_icon_small {
      padding-left: 5px;
      padding-right: 5px;
      > .btn_icon {
        display: block;

        span {
          display: block;
          font-size: 16px;
          line-height: 20px;
          width: 20px;
          text-align: center;
        }
      }
    }
  }

  &.btn_big {
    padding: 10px 20px;
  }

  &.btn_border {
    border-width: 1px;
    border-style: solid;
    padding: 4px 9px;

    &:hover {
    }
  }

  &.btn_view_toggle {
    padding: 10px 10px;
    background-color: $white;
    @include border-radius(0);
    @include border-top-radius(4px);

    &.view_active {
      background-color: $starling_black;
      color: $app_color;
    }
  }

  &.btn_wide {
    min-width: 140px;
  }

  &.btn_full {
    display: block;
    width: 100%;
  }

  &.btn_action_bar {
    padding: 10px 10px;

    .btn_icon {
      line-height: 30px;
      vertical-align: text-bottom;

      span {
        display: block;
        padding: 0px;
        font-size: 20px;
        @include box-sizing(border-box);
      }
    }

    .btn_text {
      font-size: 1.2rem;
      line-height: 30px;
    }

    .btn_helper {
      line-height: 30px;
      float: none;
      display: inline-block;
    }
  }

  &.btn_pulldown_trigger {
    background-color: $white;
    @include border-top-radius(0);
  }

  > .btn_text {
    line-height: 20px;
    display: inline-block;

    &.hidden_text {
      position: absolute;
      font-size: 0 !important;
    }
  }

  > .btn_icon,
  > .btn_status {
    position: relative;
    pointer-events: none;
    display: inline-block;
    vertical-align: text-top;
    color: inherit;
    line-height: 20px;

    span {
      font-size: 16px;
      display: block;
    }

    &.big_icon {
      span {
        font-size: 20px;
      }
    }
  }

  > .btn_status {
    &.status_invalid {
      color: $red;
    }
  }

  > .btn_helper {
    float: right;
    margin-left: 5px;
    line-height: 20px;

    span {
      // font-size: 10px;
      display: block;
      line-height: 20px;
    }
  }

  > .btn_icon_arrow {
    float: right;
    margin-left: 5px;
    line-height: 20px;

    span {
      font-size: 16px;
      display: block;
      line-height: 20px;
    }
  }
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

// .btn_group {

// 	.btn_group_left {
// 		float: left;
// 		padding: 4px;
// 		border-right: 0px solid !important;
// 		@include border-radius(4px 0 0 4px);

// 		&.btn_no_border {
// 			padding: 5px;
// 		}

// 		&.btn_grey {
// 			padding: 4px;
// 			border:1px solid $light_grey !important;

// 			&:focus,
// 			&:hover {
// 				border:1px solid $dark_grey !important;
// 			}

// 			&:disabled {
// 				color: $grey !important;
// 				cursor: default !important;
// 				background-color: $lighter_grey !important;
// 				border-color: $lighter_grey !important;

// 				&:hover,
// 				&:focus {
// 					border-color: $lighter_grey !important;
// 					background-color: $lighter_grey !important;
// 					color: $grey !important;
// 				}
// 			}
// 		}
// 	}

// 	.btn_group_right {
// 		float: left;
// 		padding: 4px;
// 		@include border-radius(0 4px 4px 0);

// 		&.btn_no_border {
// 			padding: 5px;
// 		}

// 		&.btn_grey {
// 			padding: 4px;
// 			border:1px solid $light_grey !important;

// 			&:focus,
// 			&:hover {
// 				border:1px solid $dark_grey !important;
// 			}

// 			&:disabled {
// 				color: $grey !important;
// 				cursor: default !important;
// 				background-color: $lighter_grey !important;
// 				border-color: $lighter_grey !important;

// 				&:hover,
// 				&:focus {
// 					border-color: $lighter_grey !important;
// 					background-color: $lighter_grey !important;
// 					color: $grey !important;
// 				}
// 			}

// 		}
// 	}
// }
