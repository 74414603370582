.col_group {
	display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:flex-start;
    -webkit-align-items:flex-start;
    width: 100%;
    min-height:0;

    &.centered {
        justify-content: space-around;

        .col_item {
            margin:0 20px;
            flex:1 1 100%;
            text-align: center;

            &:first-child {
                text-align: center;
            }
                &:last-child {
                text-align: center;
            }
        }
    }

    &.has_padding {
        margin:10px 0;
    }

    &.offset_margin {
        margin-left: -10px;
        margin-right: -10px;
        width: calc(100% + 20px);
        margin-bottom:-20px;
    }


	.col_item {
		// flex: 1 1 100%;
		@include box-sizing(border-box);

        &:first-of-type {
            >ul {
                margin:0;
                padding:0;
                list-style: none;
                font-size: 0;
                >li {
                    display: inline-block;
                    font-size: $font_size; 
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        &:last-of-type {

            &.text_right {
                text-align: right;
            }

            >ul {
                margin:0;
                padding:0;
                list-style: none;
                font-size: 0;
                >li {
                    display: inline-block;
                    font-size: $font_size; 
                    margin-left: 10px;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        &.width_1_4 {
            flex:0 1 25%;
            text-align: left;
            padding-left: 10px;
            padding-right: 10px;
        }

        &.width_2_4 {
            flex:0 1 50%;
            text-align: left;
            padding-left: 10px;
            padding-right: 10px;
        }

        &.width_3_4 {
            flex:0 1 75%;
            text-align: left;
            padding-left: 10px;
            padding-right: 10px;
        }

        &.width_4_4 {
            flex:0 1 100%;
            text-align: left;
            padding-left: 10px;
            padding-right: 10px;
        }
	}

    &.col_2 {
        flex-wrap: wrap;
        -webkit-flex-wrap:wrap;

        .col_item {
            flex: 0 0 calc(50% - 10px);
            
            &:first-child {
                margin-right: 10px;
            }
            
            &:last-child {
                margin-left: 10px;
            }
        }
    }

    &.col_4 {
        flex-wrap: wrap;
        -webkit-flex-wrap:wrap;

        .col_item {
            flex: 1 1 25%;

            &:last-of-type {
                text-align: left;
            }
        }
    }
}