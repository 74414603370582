#side_navigation {
  @include transition(width 0.3s ease 0.15s);
  @include box-shadow(0 0 10px rgba(0, 0, 0, 0.4));
  background-color: $navy;
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 50px;
  z-index: 10;

  &.active,
  &.pinned {
    width: var(--navigation-width);

    a.nav_link {
      .nav_link_text {
        opacity: 1;
      }
    }

    a.sub_nav_link {
      .sub_nav_link_text {
        opacity: 1;
        display: flex;
        align-items: center;
      }
    }

    .nav_section_header {
      @include opacity(1);

      .nav_section_title {
        font-size: $font-size;
      }
    }

    button.btn_nav_expand {
      display: block;
    }

    .nav_link_options {
      display: block;
    }

    #nav_header {
      .product_title {
        display: block;
      }

      .menu_pin {
        opacity: 1;
      }

      .product_grid {
        right: 0px;
      }
    }
  }

  a.sub_nav_link {
    .sub_nav_link_text {
      display: none;
    }
  }

  .nav_section_header {
    @include opacity(0);
    @include transition(all 0.3s ease);
  }

  button.btn_nav_expand {
    display: none;
  }

  .nav_link_options {
    display: none;
  }

  #nav_header {
    .product_title {
      display: none;
    }

    .menu_pin {
      transition: opacity 0.3s ease 0.3s, transform 0.3s ease;
      opacity: 0;
    }

    .product_grid {
      right: 5px;
    }
  }

  & + .offset_for_nav {
    width: calc(100% - 50px);
    margin-left: 50px;
  }

  &.pinned + .offset_for_nav {
    width: calc(100% - var(--navigation-width));
    margin-left: var(--navigation-width);
  }
}

#side_navigation_inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: white;
  overflow: hidden;

  .nav_section {
    width: 100%;

    &.scrollable {
      overflow: auto;
      overflow-x: hidden;
    }
  }

  .nav_divider {
    border-top: 1px solid rgba(white, 0.2);
    height: auto;
    display: block;
    width: 100%;
  }

  #nav_toggle {
    padding: 5px 0;
    text-align: center;
  }

  .body {
    overflow: hidden auto;
    width: 100%;
  }

  #nav_footer {
    border-top: 1px solid rgba(white, 0.2);
    height: 4rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;

    #user-area-btn {
      height: 4rem;
      display: flex;
      align-items: center;

      .nav_link_icon {
        height: 4rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
      }
    }
  }
}

button {
  &.btn_big_icon {
    padding: 6px;
    margin: 4px;
    @include box-sizing(border-box);

    .btn_icon {
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      display: inline-block;
      vertical-align: top;

      i,
      em,
      span {
        transition: transform 0.15s ease;
        display: block;
        line-height: 16px !important;
        width: 16px;
        text-align: center;
        font-size: 16px;
        padding: 2px;
      }
    }

    .btn_text {
      font-size: 1rem;
    }

    &.btn_icon_only {
      .btn_icon {
        vertical-align: top;
      }

      .btn_text {
        position: absolute;
        font-size: 0;
      }
    }

    &:hover {
      background-color: rgba(white, 0.1);
    }
  }

  &.btn_nav_small {
    padding: 4px;

    .btn_icon {
      transition: transform 0.3s ease-in-out;
      vertical-align: top;

      i,
      em,
      span {
        display: block;
        line-height: 16px !important;
        width: 16px;
        text-align: center;
        font-size: 12px;
        padding: 4px;
      }
    }

    .btn_text {
      position: absolute;
      font-size: 0;
    }

    &:hover {
      background-color: rgba(white, 0.1);
    }

    &.btn_active {
      background-color: rgba(white, 0.1);
    }
  }

  &.btn_nav_options {
    position: absolute;
    right: 0;
    top: 0;
    @include opacity(0.5);

    &:hover {
      @include opacity(1);
    }
  }
}

.nav_link_options {
  position: absolute;
  right: 4px;
  top: 4px;
  display: none;

  button.btn_dropdown {
    @include opacity(0.5);

    .btn_icon {
      i,
      em,
      span {
        display: block;
        line-height: 16px !important;
        width: 16px;
        text-align: center;
        font-size: 12px;
        padding: 4px;
      }
    }

    &:hover {
      @include opacity(1);
      background-color: rgba(white, 0.1);
    }
  }
}

.nav_list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    position: relative;

    &:hover {
      .nav_link_options {
        display: block;
      }
    }
  }
}

.sub_nav_list {
  background-color: rgba(1, 1, 1, 0.2);
  box-shadow: inset 0 0.6rem 0.5rem -0.5rem rgb(0 0 0 / 50%), inset 0 -0.5rem 0.5rem -0.5rem rgb(0 0 0 / 50%);
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  transition: height 0.3s ease-out, opacity 0.3s ease-out;

  li {
    position: relative;

    &:hover {
      .nav_link_options {
        display: block;
      }
    }
  }
}

a.nav_link,
.nav_link {
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  height: 2rem;
  padding: 0 0.5rem;
  position: relative;

  &:hover {
    text-decoration: none;
    background-color: rgba(white, 0.1);
  }

  .nav_link_icon {
    display: flex;
    padding: 0.5rem 1rem;
    position: absolute;
    left: 0;
    top: 0;
    height: calc(100% - 1rem);
    line-height: calc(100% - 1rem);
    align-items: center;

    i,
    em,
    span {
      width: 1rem;
      text-align: center;
      font-size: 1rem;
    }
  }

  .nav_link_text {
    @include transition(all 0.3s ease);
    @extend .ellipsis;
    font-size: $font_size;
    display: block;
    opacity: 1;
    margin-left: 50px;
    line-height: 20px;

    .nav_link_expand_icon {
      margin-left: auto;
      color: rgb(255 255 255 / 25%);

      i {
        transition: transform 0.35s;

        &.expanded {
          transform: rotate(90deg);
        }
      }
    }
  }

  &.status_active {
    background-color: rgba(white, 0.1);
    color: white;
    font-weight: 500;

    .sub_nav_link_icon i.rounded-number {
      background: white;
      color: #455765;
    }

    &:after {
      background: white;
      content: '';
      width: 3px;
      top: 0;
      bottom: 0;
      left: 0;
      position: absolute;
    }
  }

  &.nav_link_workspace {
    .nav_link_text {
      padding-right: 40px;
    }
  }

  &.status_alert {
    .nav_link_icon {
      :after {
        content: '';
        height: 6px;
        width: 6px;
        position: absolute;
        right: 12px;
        top: 8px;
        z-index: 1;
        background: orange;
        @include border-radius(6px);
      }
    }
  }
}

a.sub_nav_link,
.sub_nav_link {
  align-items: center;
  color: rgba(white, 0.7);
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  height: 2rem;
  position: relative;

  &:hover {
    color: white;
    text-decoration: none;
    background-color: rgba(white, 0.1);
  }
  &[disabled],
  &[disabled]:hover {
    background-color: unset;
    color: rgba(white, 0.5);
    cursor: default;
    filter: grayscale(1) opacity(0.4);

    i {
      outline-color: rgba(white, 0.5);
    }
  }

  .sub_nav_link_icon {
    display: flex;
    align-items: center;
  }

  .sub_nav_link_text {
    @include transition(all 0.3s ease);
    @extend .ellipsis;
    font-size: $font_size;
    display: block;
    opacity: 0;
  }

  &.status_active {
    background-color: rgba(white, 0.1);
    color: white;
    font-weight: 500;

    .sub_nav_link_icon i.rounded-number {
      background: white;
      color: #455765;
    }

    &:after {
      background: white;
      content: '';
      width: 3px;
      top: 0;
      bottom: 0;
      left: 0;
      position: absolute;
    }
  }

  &.status_alert {
    .sub_nav_link_icon {
      :after {
        content: '';
        height: 6px;
        width: 6px;
        position: absolute;
        right: 12px;
        top: 8px;
        z-index: 1;
        background: orange;
        @include border-radius(6px);
      }
    }
  }
}

.nav_section_header {
  font-size: 0;
  width: 100%;
  position: relative;
  margin-bottom: 4px;

  .nav_section_title {
    display: inline-block;
    vertical-align: top;
    line-height: 20px;
    padding: 6px 0 6px 10px;
    text-transform: uppercase;
    font-size: 12px;
    color: rgba(white, 0.5);
    margin-right: 40px;
  }

  .nav_section_actions {
    display: inline-block;
    font-size: $font-size;
    vertical-align: top;
    margin-left: auto;
    position: absolute;
    right: 4px;
    top: 0px;
  }
}

#nav_header {
  @include box-sizing(border-box);

  align-items: center;
  display: flex;
  height: 50px;
  min-height: 50px;
  padding-left: 0.6rem;
  position: relative;

  .product_title {
    .product_name {
      font-size: 14px;
      line-height: 20px;
      margin: 0 6px;
      text-transform: uppercase;
    }
  }

  .menu_pin {
    position: absolute;
    right: 32px;
    top: 5px;

    #btn_pin_menu {
      .btn_icon {
        @include transition(width 0.3s ease);
      }

      &.pin_active {
        .btn_icon {
          @include rotate(15);
        }
      }
    }
  }

  .product_grid {
    position: absolute;
    right: 0;
    top: 5px;
  }
}

/* Dropdown Content (Hidden by Default) */
.options_content {
  display: none;
  min-width: 180px;
  top: 100%;
  background: white;
  padding: 0px;
  @include border-radius(4px);
  box-shadow: 0 1px 4px rgba($black, 0.3), 0 5px 20px rgba($black, 0.1);
  @include box-sizing(border-box);
  max-height: calc(50vh + 50px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  z-index: 999;
  color: $app_color;
  text-align: left;

  &.no_scroll_bar {
    max-height: inherit;
  }

  &.has_padding {
    padding: 15px;
  }

  .options_content_body {
    padding: 15px;

    &.no_padding {
      padding: 0;
    }

    p {
      &:last-of-type {
        margin: 0;
        padding: 0;
      }
    }
  }
}

/* Show the dropdown menu (use JS to add this class to the .options_content container when the user clicks on the dropdown button) */
.options_content.show {
  display: block;
}

.margin-top-0 {
  margin-top: 0 !important;
}
.margin-bottom-0 {
  margin-bottom: 0 !important;
}
.margin-left-0 {
  margin-left: 0 !important;
}
.margin-right-0 {
  margin-right: 0 !important;
}
.margin-all-0 {
  margin: 0 !important;
}

.padding-top-0 {
  padding-top: 0 !important;
}
.padding-bottom-0 {
  padding-bottom: 0 !important;
}
.padding-left-0 {
  padding-left: 0 !important;
}
.padding-right-0 {
  padding-right: 0 !important;
}
.padding-all-0 {
  padding: 0 !important;
}

.margin-top-auto {
  margin-top: auto !important;
}
.margin-bottom-auto {
  margin-bottom: auto !important;
}
.margin-left-auto {
  margin-left: auto !important;
}
.margin-right-auto {
  margin-right: auto !important;
}
.margin-all-auto {
  margin: auto !important;
}

.padding-top-auto {
  padding-top: initial !important;
}
.padding-bottom-auto {
  padding-bottom: initial !important;
}
.padding-left-auto {
  padding-left: initial !important;
}
.padding-right-auto {
  padding-right: initial !important;
}
.padding-all-auto {
  padding: initial !important;
}
