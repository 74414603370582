$icomoon-font-family: 'empyrean_deposits' !default;
$icomoon-font-path: 'fonts' !default;

$icon_tree: '\e92c';
$icon_transpose: '\e92b';
$icon_triangle_down: '\e928';
$icon_triangle_up: '\e929';
$icon_products: '\e92a';
$icon_asset_liability_management: '\e900';
$icon_assumptions: '\e901';
$icon_chevron_down: '\e902';
$icon_chevron_left: '\e903';
$icon_chevron_right: '\e904';
$icon_chevron_up: '\e905';
$icon_close: '\e906';
$icon_current_expected_credit_loss: '\e907';
$icon_data_set: '\e908';
$icon_delete: '\e909';
$icon_edit: '\e90a';
$icon_error: '\e90b';
$icon_funds_transfer_pricing: '\e90c';
$icon_grid: '\e90d';
$icon_ifrs: '\e90e';
$icon_liquidity_modeling: '\e90f';
$icon_lock: '\e910';
$icon_logo_empyrean: '\e911';
$icon_msg_error: '\e912';
$icon_msg_success: '\e913';
$icon_notification: '\e914';
$icon_plus: '\e915';
$icon_quicklinks: '\e916';
$icon_result_set: '\e917';
$icon_sheet_loader: '\e918';
$icon_spinner: '\e919';
$icon_stochastic_income_simulation: '\e91a';
$icon_stress_testing: '\e91b';
$icon_summary: '\e91c';
$icon_toggle_drawer_open: '\e91d';
$icon_toggle_drawer: '\e91e';
$icon_trash: '\e91f';
$icon_triangle_left: '\e920';
$icon_triangle_right: '\e921';
$icon_undelete: '\e922';
$icon_upload: '\e923';
$icon_user: '\e924';
$icon_valid: '\e925';
$icon_warning: '\e926';
$icon_dropdown: '\e927';
