#notifications {
	background-color: $white;
}


#notifications_inner {
	padding: 20px 0 0;
}


.notification {

	display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:center;
    -webkit-align-items:center;
    width: 100%;
    min-height:0;
    padding: 0px 0;
    line-height: 20px;
    background-color: $lighter_grey;
    @include border-radius(4px);
    margin-bottom: 10px;

    &:hover {
    	background-color: darken($lighter_grey,2%);
    }

    &:last-of-type {
    	margin-bottom: 0;
    }


	.notification_icon {
		padding:15px;
		font-size: 30px;
		position: relative;

		.notification_icon_main {
			margin-left: 5px;
			z-index: 1;
			position: relative;
		}

		.notification_icon_shadow {
			position: absolute;
			left:15px;
			top:20px;
			z-index: 0;
			color: rgba($app_color,0.1);
		}
	}

	.notification_details {
		padding:15px 0;
		display: flex;
	    flex-direction: row;
	    -webkit-flex-direction: row;
	    justify-content: space-between;
	    flex-wrap:nowrap;
	    -webkit-flex-wrap:nowrap;
	    align-items:stretch;
	    -webkit-align-items:stretch;
	    width: 100%;
	    min-height:0;
	    line-height: 20px;
	    flex:1 1 auto;


		.notification_text {
			padding:0px 0;
			display: flex;
		    flex-direction: row;
		    -webkit-flex-direction: row;
		    justify-content: space-between;
		    flex-wrap:wrap;
		    -webkit-flex-wrap:wrap;
		    align-items:flex-start;
		    -webkit-align-items:flex-start;
		    width: 100%;
		    min-height:0;
		    line-height: 20px;
		    flex:1 1 100%;

			.notification_title {
				font-weight: 600;
				display: block;
				flex:1 1 100%;
			}

			.notification_timestamp {
				display: block;
				flex:1 1 auto;
				color: $mid_grey;
			}

			.notification_info {
				display: block;
				flex:1 1 auto;
				color: $mid_grey;
			}
		}

		.notification_action {
			white-space: nowrap;
			a {
				text-decoration: none;
				margin-top: 10px;
				display: block;

				&:hover, &:focus {
					text-decoration: underline;
				}
			}
		} 
	}

	.notification_close {
		padding:15px;
		@include opacity(0.5);

		&:hover {
			@include opacity(1.0);
		}
	}

	&.error {

		.notification_icon {
			&:before {
				content: '';
				background-color: $white;
				height: 12px;
				width: 12px;
				z-index: 2;
				display: block;
				position: absolute;
				top: 34px;
				left:17px;
				@include border-radius(50%);
			}

			&:after{
				content: $icon_error;
				font-family: 'pipelinedl';
				position: absolute;
				top: 30px;
				left: 15px;
				color: $red;
				font-size: 16px;
				z-index: 2;
			}
		}
		.notification_details {
			.notification_text {
				.notification_title {
					color: $red;
				}

				.notification_timestamp {}
			}
		}
	}
}



