[tooltip] {
  position: relative;
}

[tooltip]::before {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px 6px 0 6px;
  border-style: solid;
  border-color: rgba($black, 0.9) transparent transparent transparent;
  z-index: 100;
  @include opacity(0);
}
[tooltip]::after {
  content: attr(tooltip);
  position: absolute;
  left: 50%;
  top: -6px;
  transform: translateX(-50%) translateY(-100%);
  background: rgba($black, 0.9);
  text-align: center;
  color: $white;
  padding: 5px 5px;
  font-size: 0.8rem;
  line-height: 140%;
  min-width: 140px;
  border-radius: 4px;
  pointer-events: none;
  @include opacity(0);
  z-index: 101;
}

[tooltip-position='left']::before {
  left: 0%;
  top: 50%;
  margin-left: -12px;
  transform: translatey(-50%) rotate(-90deg);
}
[tooltip-position='top']::before {
  left: 50%;
}
[tooltip-position='bottom']::before {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translatey(-100%) rotate(-180deg);
}
[tooltip-position='right']::before {
  left: 100%;
  top: 50%;
  margin-left: 1px;
  transform: translatey(-50%) rotate(90deg);
}
[tooltip-position='left']::after {
  left: 0%;
  top: 50%;
  margin-left: -8px;
  transform: translateX(-100%) translateY(-50%);
}
[tooltip-position='top']::after {
  left: 50%;
}
[tooltip-position='bottom']::after {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translateY(0%);
}
[tooltip-position='right']::after {
  left: 100%;
  top: 50%;
  margin-left: 8px;
  transform: translateX(0%) translateY(-50%);
}

[tooltip]:hover::after,
[tooltip]:hover::before {
  @include opacity(1);
}

.mdc-tooltip {
  white-space: pre-line;
  font-size: 0.75rem;
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
  background-color: rgba(#51738b, 0.9);
}

.tooltip-left-align {
  div[class^=mdc-tooltip_] {
    text-align: left;
  }
}
