.page_inner_container {
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
}

#page_frame {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  min-height: 0;
  width: 100%;
  transition: width 0.35s, margin 0.35s;

  #header {
    width: 100%;
  }

  #action_bar {
    width: 100%;
  }

  #notifications {
    width: 100%;
  }

  #content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: space-between;
    margin-bottom: auto;
    min-height: 0;
    width: 100%;

    #page_content_main {
      margin-bottom: auto;
      width: 100%;
      flex: 1 1 auto;
      @include box-sizing(border-box);
      overflow: auto;
      padding: 20px 10px;

      &.no_padding {
        padding: 0;
      }
    }

    #page_content_footer {
      @include box-sizing(border-box);
      width: 100%;
      padding: 0px;
      background-color: $lighter_grey;
    }
  }
}
