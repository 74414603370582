.form_upload_container {
	label {
		padding:80px 20px !important;
		// background-color: $red;
		text-align: center;
		@include border-radius(10px);
		border:2px dashed $border_color;
		display: block;

		&:hover {
			border-color: $blue;
			background-color: $hover;
		}

		.upload_icon {
			display: block;

			[class^="icon_"], 
			[class*=" icon_"] {
				display: inline-block;
				font-size: 100px;
				margin-bottom: 10px;
				color: $app_color;
			}

			h1 {
				color: $app_color;
				font-size: 1.25rem; //20px
				line-height: 30px;
				margin:0 0 10px;
				padding:0;
			}

			p {
				margin-bottom: 10px;
			}
		}

		h1 {

		}
	}
}