.section_container {
	.section_header {
		display: flex;
	    flex-direction: row;
	    -webkit-flex-direction: row;
	    justify-content: space-between;
	    flex-wrap:nowrap;
	    -webkit-flex-wrap:nowrap;
	    align-items:space-between;
	    -webkit-align-items:space-between;
	    width: 100%;
	    min-height:0;
	    margin-bottom: 10px;


		.section_header_left {
			>ul {
				margin:0;
				padding:0;
				list-style: none;
				font-size: 0;

				>li {
					margin-right: 10px;
					font-size: $font_size;
					display: inline-block;

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}

		.section_header_right {
			>ul {
				margin:0;
				padding:0;
				list-style: none;
				font-size: 0;

				>li {
					margin-left: 10px;
					font-size: $font_size;
					display: inline-block;

					&:first-child {
						margin-left: 0;
					}
				}
			}
		}
	}

	.section_content {}
}