html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
}

body {
  font-size: $font_size;
  font-family: $font_pack;
  font-weight: 400;
  color: $app_color;
  background: $body_background;
  -webkit-text-size-adjust: none;
  margin: 0px;
  padding: 0;

  &.no_scroll {
    height: 100%;
    overflow: hidden;
  }

  scrollbar-color: #2b495ea8 #edf1f6;
  scrollbar-width: thin;

  *::selection {
    background: $primary_color !important;
    color: white;
  }

  .swal2-container {
    z-index: 11000 !important;
  }
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0px;
  padding: 0px;
}

abbr {
  text-decoration: none;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
  line-height: 140%;
}

*:focus {
  outline: none;
}

.clearfix::after {
  clear: both;
  content: '';
  display: block;
  height: 0;
  visibility: hidden;
}

.overflow {
  -webkit-overflow-scrolling: touch; /* lets it scroll lazy */
  overflow: auto;
}

.hidden_text {
  position: absolute !important;
  font-size: 0 !important;
}

.text_right {
  text-align: right !important;
}

.text_center {
  text-align: center !important;
}

.align_center {
  text-align: center;
}

.space_divider {
  height: 10px;
  display: block;

  &.extra_padding {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.line_divider {
  background-color: rgba($black, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;
  height: 1px;
  display: block;

  &.extra_padding {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.truncate,
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fine_print,
.timestamp {
  font-size: 0.8rem;
  line-height: 20px;
  color: $mid_grey;
  padding: 5px 0;
}

ul.list_inline {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0;

  > li {
    font-size: $font_size;
    margin: 0 5px;
    display: inline-block;
  }

  &.pull_left {
    > li {
      &:first-child {
        margin-left: 0;
      }
    }
  }

  &.pull_right {
    > li {
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.grey_container {
  padding: 10px 20px;
  border: 1px solid $border_color;
}

.toast-div.data-import.error {
  margin-top: 8px;
  background: rgba(1, 1, 1, 0.1);
  padding: 4px 8px;
  border-radius: 4px;
}

.toast-ul.no-margin.data-import {
  margin: 0 0 0 -24px;
}

.toast-ul.no-top-margin.data-import {
  margin-top: 0px;
}
