#content {

}

#page_content_main {}

.dashboard_grid_item {
	margin-bottom: 40px; 
	// padding: 20px;
	// border:0px solid $dark_grey;
	// @include border-radius(4px);
	// @include box-shadow(0 2px 5px rgba($black,0.3), 0 -2px 5px rgba($black,0.1));
	position: relative;
	background-color: $white;

	&:last-of-type {
		margin-bottom: 0;
	}

	.dashboard_grid_header {
		.header_row {
			.cell_row {
				color: $app_color;
				border-bottom: 1px solid $app_color;
    			text-transform: uppercase;
				font-weight: 600;
				font-size: 0.8rem;

				&:hover {
					background-color: transparent;
				}

				.product_data {
					.cell_header {
						padding: 10px 5px;
					}
				}
			}
		}
	}

	.dashboard_grid_content {
		.product_row {
			.cell_row {
				.product_data {
					.product_name {
						padding-left: 20px;
					}
				}

			}
			&:last-of-type {
				.cell_row {
					border-bottom: 1px solid $border_color;
				}
			}
		}
	}

	.dashboard_grid_total {
		.footer_row {
			.cell_row {
				border-bottom: 0px solid $dark_grey;
    			background-color: rgba($primary_color,0.05);

    			.product_data {
					.product_name {
						padding-left: 20px;
					}
				}
			}
		}
	}
}


.cell_row {
	border-bottom: 1px solid $border_color;
    margin-bottom: auto;
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:flex-start;
    -webkit-align-items:flex-start;
    width: 100%;
    min-height:0;

    &:hover {
    	background-color: rgba($primary_color,0.05);

    	.product_data {
    		.product_name { 
	    		a {
	    			color: $primary_color;
	    			text-decoration: underline;

	    			&.toggle_row_details {
	    				display: inline-block;
	    				text-decoration: underline;
	    				position: relative;
	    				padding-left: 10px;

	    				&:before {
	    					content: ' - ';
	    					position: absolute;
	    					left: 0;
	    					top: 0;
	    				}
	    			}

	    			&:hover {
	    				text-decoration: underline;
	    			}
	    		}
	    	}
    	}
    }

    .product_data {
    	line-height: 20px;

    	&.cell_width_1 { flex: 1 1 calc(6.25% * 1 ) }
    	&.cell_width_2 { flex: 1 1 calc(6.25% * 2) }
    	&.cell_width_3 { flex: 1 1 calc(6.25% * 3) }
    	&.cell_width_4 { flex: 1 1 calc(6.25% * 4) }
    	&.cell_width_5 { flex: 1 1 calc(6.25% * 5) }
    	&.cell_width_6 { flex: 1 1 calc(6.25% * 6) }
    	&.cell_width_7 { flex: 1 1 calc(6.25% * 7) }
    	&.cell_width_8 { flex: 1 1 calc(6.25% * 8) }
    	&.cell_width_9 { flex: 1 1 calc(6.25% * 9) }
    	&.cell_width_10 { flex: 1 1 calc(6.25% * 10) }
    	&.cell_width_11 { flex: 1 1 calc(6.25% * 11) }
    	&.cell_width_12 { flex: 1 1 calc(6.25% * 12) }
    	&.cell_width_13 { flex: 1 1 calc(6.25% * 13) }
    	&.cell_width_14 { flex: 1 1 calc(6.25% * 14) }
    	&.cell_width_15 { flex: 1 1 calc(6.25% * 15) }
    	&.cell_width_16 { flex: 1 1 calc(6.25% * 16) }

    	.cell_header {
    		padding: 5px 5px;
    		font-weight: 600;

    		h3 {
    			line-height: 20px;
    			font-size:1.00rem;
    			text-transform: initial;
    			font-weight: 400;
    		}
    	}

    	.product_name {
    		padding: 5px 5px;

    		a {
    			color: $primary_color;
	    		text-decoration: underline;

    			&.toggle_row_details {
    				text-decoration: underline;
    				display: none;

    				&:before {
    					content: ' - ';
    				}
    			}

    			&:hover {
    				text-decoration: underline;
    			}
    		}
    	}

    	.value {
    		padding: 5px 5px;
    		
    		&.negative {
    			color: $red;
    		}

    		&.actual {
    			display: none;
    		}
    		&.budget {
    			display: none;
    		}

    		&.variance {
    		}

    		
    	}
    }

    &.show_details {
    	// background-color: rgba($primary_color,0.05);

    	.product_data {
    		.value {
	    		&.actual {
	    			display: block;
	    		}
	    		&.budget {
	    			display: block;
	    		}
	    		&.variance {
	    			border-top:1px solid $border_color;
	    			background-color: rgba($primary_color,0.05);
	    			display: block;
	    		}
	    	}
    	}	
    }
}


