table.table_grid {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	empty-cells: show;
	background-color: $white;
	@include box-sizing(border-box);
	font-size: $font_size;
	
	&.hidden_header {
		thead {
			position: absolute;
			font-size: 0;
			padding: 0;
			height: 0;
			width: 0;
			display: block;

			tr {
				td,th {
					font-size: 0 !important;
					padding: 0 !important;
					height: 0 !important;
					width: 0 !important;
					@include opacity(0);
				}
			}
		}
	}
	
	thead {
		tr {
			th,td {
				text-align: left;
				background-color: $light_grey;
				vertical-align: top;
				padding: 5px 10px;
				line-height: 20px;
				min-height: 20px;
				border-bottom: 1px solid $grey;
				border-right: 1px solid $grey;

				&:last-of-type {
					border-right:0 solid;
				}

				&.col_checkbox {
					width: 20px;
					padding: 0 5px !important;

					.form_row {
						min-width: 20px;
					}
				}

				&.col_icon {
					width: 30px;
				}

				[class^="icon_"], 
				[class*=" icon_"] {
					font-size: 16px;
					display: inline-block;
					vertical-align: text-bottom;
				}

				&:empty {
					height: 20px;
				}
				
			}
		}
	}
	
	tbody,tfoot {
		tr {
			th,td {
				border-bottom: 1px solid $border_color;
				vertical-align: top;
				padding: 5px 10px;
				line-height: 20px;
				min-height: 20px;

				&.col_chbox {
					width: 20px;
					padding: 0 5px !important;

					.form_row {
						min-width: 20px;
					}
				}

				&.col_icon {
					width: 20px;
				}

				&:empty {
					height: 20px;
				}

				&.nowrap {
					white-space: nowrap;
				}

				&.changed {
					background-color: rgba($yellow,0.5) !important;
				}

				.table_btn_container {
					display: block;
					float: left;
					margin: 0px 0;
					vertical-align: top;

					button {
						height: 30px;
						vertical-align: top;
					}
				}

				[class^="icon_"], 
				[class*=" icon_"] {
					font-size: 16px;
					display: inline-block;
					vertical-align: top;
					line-height: 20px;
				}

				.form_row.table_input {

					select.inputbox,
					input.inputbox {
						margin-top:-5px;
						margin-bottom: -5px;
						min-width: 120px;
						background-color: transparent;
						border-color:transparent;
					}
				}

				.btn_table {
					margin:-3px 0;
					padding-top: 3px;
					padding-bottom: 3px;
				}

			}

			&:hover {
				>td,
				>th {
					background-color: $state_hover;
				}
			}

			&.row_swog {
				>th,
				>td {
					// 

					&:first-child {
						background-color: $lighter_grey;
					}
				}
			}

			&.row_multipiece {
				>th,
				>td {
						background-color: $body_background;
				}
			}

			&.selected_row {
				>th,
				>td {
					background-color: $hover !important;

					a {
						// color: inherit;
					}
				}
			}

			&.row_hidden {
				display: none;

				&.row_visible {
					display: table-row;
				}
			}

			&.row_visible {
				display: table-row;
			}

		}
	}

	&.no_wrap {
		thead {
			tr {
				th,td {
					white-space: nowrap;
				}
			}
		}

		tbody {
			tr {
				td,th {
					white-space: nowrap;
				}
			}
		}
	}
}


// Button Options for Selected Table Row

#table_selection_options {
	float: left;
	display: none;
	margin-left: 10px;
	border-left: 1px solid $grey;
	padding-left: 10px;
}


.scroll_container {
	overflow: auto;
	min-height:160px; // This is purely for aesthetics
}

.fixed_height {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow: auto;
}





.table_placeholder {
	min-height: 480px;
	position: relative;
	background-color: $lighter_grey;

	.table_loader {
		position: absolute;
		@include translate(-50%,-50%);
		left: 50%;
		top: 50%;
		text-align: center;
		max-width: 240px;
		@include opacity(0.5);

		.table_loader_icon {
			font-size: 40px;
			margin-bottom: 10px;
		}

		.table_loader_text {
			h4 {
				line-height: 20px;
				padding-bottom: 10px;
				font-size:1.25rem;
			}

			p {}
		}
	}
}


tr.active {
	.data_edit_row {
		display: block;
	}

	.data_value_row {
		display: none;
	}
}

.data_edit_row {
	display: none;
}

.data_value_row {
	display: block;
}


.table_header {
		white-space: nowrap;
	&.no_wrap {
		white-space: nowrap;
	}

	.form_row {
		margin-top: -5px;
		margin-bottom: -5px;
	}
}


.data_off_row {
	&.hidden {
		display: none;
	}
}

.data_on_row {
	&.hidden {
		display: none;
	}
}

.negative {
	color: $red;
}